import { images } from '@/asset';
import { dashboardImage } from '@/asset/images/dashboard';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import CustomLayout from '@/components/layout';
import { config } from '@/config';
import { PATH } from '@/constants';
import { ActionType, BREAKPOINT, CriteriaType } from '@/constants/enum';
import useScope from '@/hooks/Scope';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { bannerActiveAppSelector } from '@/redux/slice/banner.slice';
import blockListSlice, { settingSelector } from '@/redux/slice/blockList.slice';
import settingsSlice from '@/redux/slice/settings.slice';
import { Banner, Button, Card, InlineGrid, Text } from '@shopify/polaris';
import mixpanel from 'mixpanel-browser';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { HomePageStyled } from './styled';
import ReviewBanner from '@/components/ReviewBanner';

function HomePage() {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const isTablet = useMediaQuery({ maxWidth: BREAKPOINT.MD });
  const scope = useScope();
  const bannerActiveApp = useSelector(bannerActiveAppSelector);
  const blockRuleSelected = useSelector(settingSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = apiCaller.useGetGeneralDetailQuery(undefined);
  const overview = apiCaller.useOverviewSettingsQuery().data?.overview;

  const configMostPopularRules = useMemo(() => {
    return [
      {
        title: 'Block IP address',
        description: 'Protect your store by blocking unwanted IP addresses.',
        onAction: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              criteria: CriteriaType.IpAddress,
              type: ActionType.Block,
            }),
          );
        },
      },
      {
        title: 'Redirect IP address ',
        description: 'Save your store by setting up a redirect to another URL.',
        onAction: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              criteria: CriteriaType.IpAddress,
              type: ActionType.Redirect,
            }),
          );
        },
      },
      {
        title: 'Auto-block visitors',
        description: 'Prevent visitors visit to your store.',
        onAction: () => {
          navigate(PATH.SETTINGS);
          dispatch(settingsSlice.actions.handleSelected(['blocker']));
        },
      },
    ];
  }, [blockRuleSelected, dispatch, navigate]);

  const handleTurnOn = () => {
    navigate(PATH.SETTINGS);
    dispatch(slice.settingsSlice.actions.handleSelected(['general']));
    dispatch(slice.banner.actions.handleBannerActiveApp(false));
  };

  const handleLearnMore = () => {
    mixpanel.track('W_what_we_can_help_you');
    window.open('https://docs.ipblocker.io/');
  };

  const handleLearnMoreSynctrack = () => {
    window.open('https://www.wix.com/app-market/synctrack', '_blank');
  };
  const handleInstallConsentik = () => {
    window.open(' https://www.wix.com/app-market/consentik-gdpr-cookie-banner?referral=partner&referralTag=blockify&referralSectionName=dashboard-page', '_blank');
  };

  const sendMessage = () => {
    mixpanel.track('SF_ready_to_assist');
    const sendText = 'I would like to request a review of my Blockify setup.';
    try {
      $crisp.push(['set', 'session:event', ['ReviewStoreBlockify']]);
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
      // dispatch(slice.banner.actions.handleShowBannerReviewSetUpApp(false));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <HomePageStyled>
      <CustomLayout
        layoutProps={{
          title: `Hello ${config.site ? config.site[1] : ''},`,
        }}
      >
        <Text variant="bodyLg" as="span">
          Welcome to Blockify 🎉
        </Text>

        <ReviewBanner top={16} />

        <div className="mt-16 mb-16">
          {data && !data?.settings.user.enableApp && bannerActiveApp ? (
            <Banner title="Activate Blockify" action={{ content: 'Turn on', onAction: handleTurnOn }} tone="warning">
              <RegularText>
                For Blockify to work seamlessly with your website, please make sure to turn on the app in the Settings
              </RegularText>
            </Banner>
          ) : null}
        </div>

        {/* <Collapsible
          id="banner-check-free-plan"
          open={data?.settings.user.plan === UserPlan.FREE && bannerNoticeFreePlan}
          transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
        >
          <div className="mt-16">
            <Banner
              onDismiss={() => {
                dispatch(slice.banner.actions.handleBannerNoticeFreePlan(false));
              }}
            >
              {totalRulesFree === 0 ? (
                <RegularText>
                  You are currently in <b>Free plan</b>. Upgrade to get unlimited access of Blockify.
                </RegularText>
              ) : (
                <RegularText>
                  You have reached limitation of the <b>Free plan ({totalRulesFree}/6 rules)</b>. Upgrade to unlock unlimited
                  access to Blockify.{' '}
                </RegularText>
              )}
              <Link onClick={() => navigate(PATH.SETTINGS)}>Learn more.</Link>
              <br />
              <div className="mt-8">
                <Button onClick={() => navigate(PATH.SETTINGS)}>
                  {totalRulesFree === 0 ? 'See pricing plan' : 'Increase limit'}
                </Button>
              </div>
            </Banner>
          </div>
        </Collapsible> */}

        {/* Overview */}
        <div className="mt-16">
          <div className="space-between">
            <Text as="span" variant="headingLg">
              Overview
            </Text>
            <RegularText> Last 30 days</RegularText>
          </div>
          <div className="mt-8">
            <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 3, xs: 1 }} gap={'500'}>
              <Card>
                <BoldText>Total active rules</BoldText>
                <div className="mt-8">
                  <Text as="span" variant="headingLg">
                    {overview?.totalActiveRules || 0}
                  </Text>
                </div>
              </Card>
              <Card>
                <BoldText>Total visitors</BoldText>
                <div className="mt-8">
                  <Text as="span" variant="headingLg">
                    {overview?.visitors.total || 0}
                  </Text>
                </div>
                <div className="mt-8">
                  <Text as="span" tone={overview?.visitors.compare === 'increase' ? 'success' : 'critical'}>
                    {overview?.visitors.compare === 'increase' ? '+' : '-'}
                    {overview?.visitors.percent || 0}%{' '}
                  </Text>
                  month over month
                </div>
              </Card>
              <Card>
                <BoldText>Total IPs blocked</BoldText>
                <div className="mt-8">
                  <Text as="span" variant="headingLg">
                    {overview?.blocked.total || 0}
                  </Text>
                </div>
                <div className="mt-8">
                  <Text as="span" tone={overview?.blocked.compare === 'increase' ? 'success' : 'critical'}>
                    {overview?.blocked.compare === 'increase' ? '+' : '-'}
                    {overview?.blocked.percent || 0}%{' '}
                  </Text>
                  month over month
                </div>
              </Card>
            </InlineGrid>
          </div>
        </div>

        <div className="mt-16">
          <InlineGrid columns={isTablet ? 1 : 2} gap={'400'}>
            <Card padding={'600'}>
              <BoldText>Most popular rules</BoldText>
              <div className="mt-16 most-popular-rules-container">
                {configMostPopularRules.map((item, index) => {
                  return (
                    <div key={index} className="d-flex most-popular-item mt-8">
                      <div>
                        <BoldText>{item.title}</BoldText>
                        <RegularText>{item.description}</RegularText>
                      </div>
                      <Button
                        disabled={scope.isViewOnly}
                        onClick={!scope.isViewOnly ? item.onAction : undefined}
                        variant="primary"
                      >
                        Add
                      </Button>
                    </div>
                  );
                })}
              </div>
            </Card>
            <div className="blockify-helper-center-container">
              <div className="blockify-helper-center">
                <Card padding={'600'}>
                  <Text as="span" variant="headingLg">
                    Blockify help center
                  </Text>
                  <div className="mt-16 w-100">
                    <Card>
                      <div className="inline-grid-br-12">
                        <InlineGrid columns={isMobile ? 1 : 2} gap={'0'}>
                          <div
                            onClick={sendMessage}
                            className={
                              isMobile ? 'blockify-helper-center-item border-bottom' : 'blockify-helper-center-item border-right'
                            }
                          >
                            <BoldText>Get support from agent</BoldText>
                            <img src={dashboardImage.agent} alt="dashboard-agent" />
                          </div>
                          <div onClick={handleLearnMore} className="blockify-helper-center-item ">
                            <BoldText>Read helpdesk articles</BoldText>
                            <img src={dashboardImage.book} alt="dashboard-book" />
                          </div>
                        </InlineGrid>
                      </div>
                    </Card>
                  </div>
                </Card>
              </div>
            </div>
          </InlineGrid>
          <div className="mt-16" id="banner-2">
            <BannerTemplate
              src={images.consentik}
              onClick={() => {
                window.open(
                  ' https://www.wix.com/app-market/consentik-gdpr-cookie-banner?referral=partner&referralTag=blockify&referralSectionName=dashboard-page',
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            >
              <BoldText>
                Enhance User Trust and Meet GDPR & CCPA Requirements with Consentik Cookie Banner.
              </BoldText>
              <div className="mt-8">
                <RegularText>
                  Ensure your website complies with GDPR and CCPA regulations effortlessly. Our comprehensive cookie banner app provides customizable options to manage user consent and boost trust. Seamlessly integrate it into your site to protect user privacy and enhance transparency.
                </RegularText>
              </div>
              <div className="mt-16">
                <Button onClick={handleInstallConsentik}>Install FREE app</Button>
              </div>
            </BannerTemplate>
          </div>
          <div className="mt-16" id="banner-1">
            <BannerTemplate
              src={images.promote}
              onClick={() => {
                window.open(
                  'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            >
              <BoldText>
                Auto-sync PayPal tracking info & Stripe. Faster PayPal funds release and build trust with PayPal
              </BoldText>
              <div className="mt-8">
                <RegularText>
                  Synctrack adds tracking info to PayPal & Stripe automatically and instantly on autopilot. That helps you reduce
                  Stripe and PayPal disputes, money on hold, PayPal limits, and reserves. Synctrack keeps your business
                  transparent, saves time, and avoids PayPal disputes!
                </RegularText>
              </div>
              <div className="mt-16">
                <Button onClick={handleLearnMoreSynctrack}>Learn more</Button>
              </div>
            </BannerTemplate>
          </div>
        </div>

        {/* <Collapsible
          open={synctrackBanner}
          id="synctrack-banner"
          transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
        >
          <div className="synctrack-banner">
            <div
              className="dismis-btn"
              onClick={() => {
                dispatch(slice.banner.actions.handleSynctrackBanner(false));
              }}
            >
              <Icon source={CancelMajor} />
            </div>
            <img
              alt="synctrack-banner"
              src={images.synctrackBanner}
              onClick={() => window.open('https://www.wix.com/app-market/synctrack', '_blank')}
            />
          </div>
        </Collapsible> */}
      </CustomLayout>
    </HomePageStyled>
  );
}

export default memo(HomePage);
