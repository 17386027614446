import { bannerReviewSetUpAppSelector } from '@/redux/slice/banner.slice';
import { Banner, Collapsible } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import RegularText from '../RegularText';
import slice from '@/redux/slice';
import useScope from '@/hooks/Scope';
import { memo } from 'react';
import mixpanel from 'mixpanel-browser';

interface IProps {
  visible?: boolean;
  top?: number;
  bottom?: number;
}

const ReviewBanner = ({ visible, bottom, top }: IProps) => {
  const scope = useScope();
  const dispatch = useDispatch();
  const bannerReviewSetUpApp = useSelector(bannerReviewSetUpAppSelector);
  const sendMessage = () => {
    mixpanel.track('SF_ready_to_assist');
    const sendText = 'I would like to request a review of my Blockify setup.';
    try {
      $crisp.push(['set', 'session:event', ['ReviewStoreBlockify']]);
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
      // dispatch(slice.banner.actions.handleShowBannerReviewSetUpApp(false));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Collapsible
      id="banner-check-review"
      open={visible !== undefined ? visible && bannerReviewSetUpApp : bannerReviewSetUpApp}
      transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
    >
      <div
        style={{
          marginBottom: bottom || 0,
          marginTop: top || 0,
        }}
        className="mt-16"
      >
        <Banner
          title="Ensure your rule works. Contact a live chat agent to review your setup."
          action={{ content: 'Review app setup', onAction: !scope.isViewOnly ? sendMessage : undefined }}
          tone="info"
          onDismiss={() => dispatch(slice.banner.actions.handleShowBannerReviewSetUpApp(false))}
        >
          <RegularText>
            Are you facing issues with bots and malicious actors disrupting your store, but unsure how to handle them? Struggling
            to use the app or find it not performing as expected? Let us review your store setup. Feel free to message us, we're
            here to assist you.
          </RegularText>
        </Banner>
      </div>
    </Collapsible>
  );
};

export default memo(ReviewBanner);
