import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiCaller } from './query';
import { blockListSlice } from './slice/blockList.slice';
import { dashboardSlice } from './slice/dashboard.slice';
import { toastSlice } from './slice/toast.slice';
import { visitorLogSlice } from './slice/visitorAnalytics.slice';
import settingsSlice from './slice/settings.slice';
import bannerSlice from './slice/banner.slice';
import { fetchLocation } from './query/location';
import { apiAdminCaller } from './query/admin';
import dataSettingsSlice from './slice/dataSettings.slice';
import storageSession from 'redux-persist/lib/storage/session';

const toastPersistConfig = {
  key: 'toast',
  storage,
};

const bannerPersistConfig = {
  key: 'banner',
  storage: storageSession,
};

const dashboardPersistConfig = {
  key: 'dashboard',
  storage,
  whitelist: ['perPage'],
};

const visitorsPersistConfig = {
  key: 'visitors',
  storage,
  whitelist: ['perPage'],
};

const blocklistPersistConfig = {
  key: 'blocklist',
  storage,
  whitelist: ['perPage'],
};

const rootReducer = combineReducers({
  // Thêm Reducer tại đây. Sample : sample : sampleReducer
  blockList: persistReducer(blocklistPersistConfig, blockListSlice.reducer),
  dashboard: persistReducer(dashboardPersistConfig, dashboardSlice.reducer),
  setting: settingsSlice.reducer,
  apiCaller: apiCaller.reducer,
  fetchLocation: fetchLocation.reducer,
  apiAdminCaller: apiAdminCaller.reducer,
  toast: persistReducer(toastPersistConfig, toastSlice.reducer),
  visitorLog: persistReducer(visitorsPersistConfig, visitorLogSlice.reducer),
  banner: persistReducer(bannerPersistConfig, bannerSlice.reducer),
  dataSettings: dataSettingsSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const customizedMiddleware = getDefaultMiddleware({
      serializableCheck: false,
    });
    return customizedMiddleware.concat(apiCaller.middleware, fetchLocation.middleware, apiAdminCaller.middleware);
  },
});

// setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
export default store;
