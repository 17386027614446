/* eslint-disable react/jsx-no-target-blank */
import CustomButton from '@/components/CustomButton';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { Enum, PATH } from '@/constants';
import { ActionType, BREAKPOINT, CriteriaType, ListType, UserPlan } from '@/constants/enum';
import options from '@/constants/options';
import { handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import blockListSlice, {
  blackListTableSelector,
  inputSearchSelector,
  openGuideSelector,
  perPageSelector,
  settingSelector,
  tabSelectedModalSelector,
  tabSelectedSelector,
  whiteListTableSelector,
} from '@/redux/slice/blockList.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import {
  Card,
  IndexFilters,
  IndexFiltersMode,
  InlineGrid,
  LegacyCard,
  LegacyTabs,
  Loading,
  Modal,
  Text,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { DeleteMinor, ExportMinor } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import TableBlackList from './components/Blacklist';
import useFilterBlacklist from './components/Blacklist/useFilter';
import TableWhiteList from './components/Whitelist';
import useFilterWhitelist from './components/Whitelist/useFilter';
import { BlockListStyled } from './styled';
import { useNavigate } from 'react-router-dom';
import ReviewBanner from '@/components/ReviewBanner';

const selectedRuleConfig = [
  {
    id: Enum.ActionType.Block,
    content: 'Block',
  },
  {
    id: Enum.ActionType.Redirect,
    content: 'Redirect',
  },
  {
    id: Enum.ActionType.Whitelist,
    content: 'Whitelist',
  },
];

const tabsConfig = [
  {
    id: 'black-list',
    content: 'Blacklist',
    panelID: 'black-list',
    children: <TableBlackList />,
  },
  {
    id: 'white-list',
    panelID: 'white-list',
    content: 'Whitelist',
    children: <TableWhiteList />,
  },
];

const BlockList = (): JSX.Element => {
  const overview = apiCaller.useOverviewSettingsQuery().data?.overview;
  const scope = useScope();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const perPage = useSelector(perPageSelector);
  const tabSelectedModal = useSelector(tabSelectedModalSelector);
  const isOpenGuide = useSelector(openGuideSelector);
  const blacklistTable = useSelector(blackListTableSelector);
  const setting = useSelector(settingSelector);
  const tabSelected = useSelector(tabSelectedSelector);
  const whitelistTable = useSelector(whiteListTableSelector);
  const inputSearch = useSelector(inputSearchSelector);

  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const { mode, setMode } = useSetIndexFiltersMode();

  const listRuleOptions = options.configRules.filter(
    (rule) =>
      rule.id !== CriteriaType.IpAddressStartWith && rule.id !== CriteriaType.IpRanges && rule.id !== CriteriaType.Province,
  );
  const exportUrl = `${process.env.REACT_APP_API_END_POINT}setting/export?instance=${config.instance}`;
  const blackListFilter = useFilterBlacklist();
  const whiteListFilter = useFilterWhitelist();

  const { data, isLoading } = apiCaller.useGetGeneralDetailQuery(config.shop);
  const [deleteAllBlacklistItem, deleteAllBlacklistItemStatus] = apiCaller.useDeleteAllBlackListSettingMutation();
  const [deleteAllWhitelistItem, deleteAllWhitelistItemStatus] = apiCaller.useDeleteAllWhiteListSettingMutation();

  const [isOpenModalDeleteAll, setIsOpenModalDeleteAll] = useState(false);

  const handleClick = useCallback(
    (id: string) => {
      dispatch(slice.blockListSlice.actions.handleErrorRule([]));
      if (id === CriteriaType.ISP) {
        dispatch(blockListSlice.actions.handleInputIsp(''));
      }

      navigate(PATH.BLOCK_PAGE);
      dispatch(
        slice.blockListSlice.actions.handleSetting({
          ...setting,
          isActive: true,
          id: undefined,
          linkRedirect: '',
          shortUrl: '',
          referUrl: '',
          shortReferUrl: '',
          note: '',
          state: [],
          city: [],
          country: [],
          ipAddress: '',
          ispName: [],
          ispCode: [],
          specificUrl: '',
          deviceType: '',
          osName: '',
          browserName: [],
          criteria: id,
          type: options.listTypeOptions[tabSelectedModal].value,
          priority:
            options.listTypeOptions[tabSelectedModal].value === ActionType.Whitelist ? ListType.WhiteList : ListType.BlackList,
        }),
      );
    },
    [dispatch, navigate, setting, tabSelectedModal],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchBlack = useCallback(
    _debounce((value: IParamsApi.IGetSettingList) => {
      dispatch(blockListSlice.actions.handleBlackListTable(value));
    }, 1000),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchWhite = useCallback(
    _debounce((value: IParamsApi.IGetSettingList) => {
      dispatch(blockListSlice.actions.handleWhiteListTable(value));
    }, 1000),
    [],
  );

  const handleInputChangeTable = useCallback(
    (search: string) => {
      if (!tabSelected) {
        dispatch(
          blockListSlice.actions.handleInputSearch({
            ...inputSearch,
            blacklist: search,
          }),
        );
        debounceSetSearchBlack({
          ...blacklistTable,
          perPage,
          search,
          page: 1,
        });
      } else {
        dispatch(
          blockListSlice.actions.handleInputSearch({
            ...inputSearch,
            whitelist: search,
          }),
        );
        debounceSetSearchWhite({
          ...whitelistTable,
          perPage,
          search,
          page: 1,
        });
      }
    },
    [blacklistTable, debounceSetSearchBlack, debounceSetSearchWhite, dispatch, inputSearch, perPage, tabSelected, whitelistTable],
  );

  const handleClearAllFilter = useCallback(() => {
    if (!tabSelected) {
      dispatch(
        slice.blockListSlice.actions.handleBlackListTable({
          ...blacklistTable,
          criteria: '',
          type: '',
        }),
      );
    } else {
      dispatch(
        slice.blockListSlice.actions.handleWhiteListTable({
          ...whitelistTable,
          criteria: '',
        }),
      );
    }
  }, [blacklistTable, dispatch, tabSelected, whitelistTable]);

  const handleDeleteAllBlacklist = useCallback(() => {
    deleteAllBlacklistItem({
      ids: undefined,
    }).then((res) => {
      dispatch(blockListSlice.actions.handleCheckDeleteAll(deleteAllBlacklistItemStatus.isSuccess));
      dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      setIsOpenModalDeleteAll(false);
    });
    // eslint-disable-next-line
  }, [deleteAllBlacklistItem, dispatch]);

  const handleDeleteAllWhitelist = useCallback(() => {
    deleteAllWhitelistItem({
      ids: undefined,
    }).then((res) => {
      dispatch(blockListSlice.actions.handleCheckDeleteAll(deleteAllWhitelistItemStatus.isSuccess));
      dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      setIsOpenModalDeleteAll(false);
    });
    // eslint-disable-next-line
  }, [deleteAllWhitelistItem, dispatch]);

  const handleSelectListType = useCallback(
    (selected: number) => {
      dispatch(blockListSlice.actions.handleTabSelectedModal(selected));
      const type = selectedRuleConfig[selected].id;
      dispatch(
        blockListSlice.actions.handleSetting({
          ...setting,
          type,
        }),
      );
    },
    [dispatch, setting],
  );

  const handleExportRules = () => {
    window.open(exportUrl);
  };

  useEffect(() => {
    dispatch(blockListSlice.actions.handleOpenGuide(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      layoutProps={{
        title: 'Blocker & Redirector ',
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <BlockListStyled isOpenGuide={isOpenGuide}>
          <ReviewBanner visible={overview && overview?.totalActiveRules > 0} bottom={16} />
          <div
            className="blocklist-wrapper"
            onClick={() => {
              dispatch(blockListSlice.actions.handleOpenGuide(false));
            }}
          />
          <div className="add-rule-wrapper">
            <Card padding={'0'}>
              <LegacyTabs tabs={selectedRuleConfig} selected={tabSelectedModal} onSelect={handleSelectListType}>
                <div style={{ padding: isMobile ? '0.5rem' : '1rem' }}>
                  <InlineGrid
                    columns={{ xl: 6, lg: 6, md: 6, sm: 3, xs: 3 }}
                    gap={{ lg: '200', md: '200', xl: '200', sm: '300', xs: '300' }}
                  >
                    {listRuleOptions.map((item, index) => {
                      return item.condition?.includes(data?.settings.user.plan || UserPlan.FREE) || scope.isViewOnly ? (
                        <Tooltip key={index} content={item.tooltip}>
                          <Card padding={'0'}>
                            <div key={index} className="add-rule-container div-disable" onClick={() => handleClick(item.id)}>
                              <img className="add-rule-img" src={item.src} alt={item.description} />
                              <Text variant="bodyMd" as="h6" alignment="center" breakWord>
                                {`${
                                  selectedRuleConfig.find((item) => item.id === options.listTypeOptions[tabSelectedModal].value)
                                    ?.content
                                } ${item.description}`}
                              </Text>
                            </div>
                          </Card>
                        </Tooltip>
                      ) : (
                        <Card key={index} padding={'0'}>
                          <div key={index} className="add-rule-container" onClick={() => handleClick(item.id)}>
                            <img className="add-rule-img" src={item.src} alt={item.description} />
                            <Text variant="bodyMd" as="h6" alignment="center" breakWord>
                              {`${
                                selectedRuleConfig.find((item) => item.id === options.listTypeOptions[tabSelectedModal].value)
                                  ?.content
                              } ${item.description}`}
                            </Text>
                          </div>
                        </Card>
                      );
                    })}
                  </InlineGrid>
                </div>
              </LegacyTabs>
            </Card>
          </div>

          <div className="card-table">
            {mode === IndexFiltersMode.Filtering ? null : (
              <>
                <div className="btn-delete">
                  <Tooltip content="Delete all">
                    <CustomButton
                      icon={DeleteMinor}
                      onClick={() => {
                        setIsOpenModalDeleteAll(true);
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="btn-export">
                  <Tooltip content="Export">
                    <CustomButton icon={ExportMinor} onClick={handleExportRules} />
                  </Tooltip>
                </div>
              </>
            )}
            <LegacyCard>
              <IndexFilters
                queryValue={!tabSelected ? inputSearch.blacklist : inputSearch.whitelist}
                queryPlaceholder="Search by IP address/country/province/city/internet provider..."
                onQueryChange={handleInputChangeTable}
                onQueryClear={() => {
                  handleInputChangeTable('');
                }}
                tabs={tabsConfig}
                selected={tabSelected}
                onSelect={(tabIndex) => {
                  dispatch(slice.blockListSlice.actions.handleTabSelected(tabIndex));
                }}
                canCreateNewView={false}
                filters={!tabSelected ? blackListFilter.filters : whiteListFilter.filters}
                appliedFilters={!tabSelected ? blackListFilter.appliedFilters : whiteListFilter.appliedFilters}
                onClearAll={handleClearAllFilter}
                cancelAction={{ onAction: () => {} }}
                mode={mode}
                setMode={setMode}
              />
              {tabsConfig[tabSelected].children}
            </LegacyCard>
          </div>
          <Modal
            open={isOpenModalDeleteAll}
            onClose={() => setIsOpenModalDeleteAll(false)}
            title="Delete all"
            primaryAction={{
              destructive: true,
              content: 'Delete',
              onAction: !tabSelected ? handleDeleteAllBlacklist : handleDeleteAllWhitelist,
              loading: deleteAllBlacklistItemStatus.isLoading || deleteAllWhitelistItemStatus.isLoading,
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => setIsOpenModalDeleteAll(false),
              },
            ]}
          >
            <Modal.Section>
              <RegularText>If you deleted all rules, you won't be able to revert it</RegularText>
            </Modal.Section>
          </Modal>
        </BlockListStyled>
      )}
    </Layout>
  );
};

export default memo(BlockList);
